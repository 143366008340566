import { contractList, contractDetail } from '@/apis/apiContract';
import { getPersonContact, getPlaceContract } from '@/apis/general';
import { campaigns, partners, statuses, teams, users } from '@/apis/apiFilter';

export default {
  namespaced: true,
  state: {
    personContact: [],
    placeContact: [],
    filterStatuses: [],
    filterPartners: [],
    filterCampaigns: [],
    filterTeams: [],
    filterUsers: [],
  },
  getters: {
    personContact(state) {
      return state.personContact;
    },
    placeContact(state) {
      return state.placeContact;
    },
    filterStatuses(state) {
      return state.filterStatuses;
    },
    filterPartners(state) {
      return state.filterPartners;
    },
    filterCampaigns(state) {
      return state.filterCampaigns;
    },
    filterTeams(state) {
      return state.filterTeams;
    },
    filterUsers(state) {
      return state.filterUsers;
    },
  },
  mutations: {
    setPersonContact(state, data) {
      state.personContact = data;
    },
    setPlaceContact(state, data) {
      state.placeContact = data;
    },
    setFilterStatuses(state, data) {
      state.filterStatuses = data;
    },
    setFilterPartners(state, data) {
      state.filterPartners = data;
    },
    setFilterCampaigns(state, data) {
      state.filterCampaigns = data;
    },
    setFilterTeams(state, data) {
      state.filterTeams = data;
    },
    setFilterUsers(state, data) {
      state.filterUsers = data;
    },
  },
  actions: {
    getFilterStatuses({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (!state.filterStatuses.length) {
          statuses()
            .then((response) => {
              commit('setFilterStatuses', response.data.data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    getFilterPartners({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (!state.filterPartners.length) {
          partners()
            .then((response) => {
              commit('setFilterPartners', response.data.data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    getFilterCampaigns({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (!state.filterCampaigns.length) {
          campaigns()
            .then((response) => {
              commit('setFilterCampaigns', response.data.data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    getFilterTeams({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (!state.filterTeams.length) {
          teams()
            .then((response) => {
              commit('setFilterTeams', response.data.data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    getFilterUsers({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (!state.filterUsers.length) {
          users()
            .then((response) => {
              commit('setFilterUsers', response.data.data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    getPersonContact({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (!state.personContact.length) {
          getPersonContact()
            .then((response) => {
              commit('setPersonContact', response.data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    getPlaceContract({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (!state.placeContact.length) {
          getPlaceContract()
            .then((response) => {
              commit('setPlaceContact', response.data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    fetchContracts(ctx, condition) {
      return new Promise((resolve, reject) => {
        contractList(condition)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchContractDetail(ctx, id) {
      return new Promise((resolve, reject) => {
        contractDetail(id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
