<template>
  <b-card no-body>
    <b-card-body class="d-flex flex-wrap">
      <contract-filter-input
        query="code"
        title="Search"
        :placeholder="$t('Search placeholder')"
        class="w-100"
      />
      <contract-filter-date query="appointment_date" title="contracts.appointment_date" />
      <contract-filter-date query="last_action_at" title="contracts.last_action_at" />
      <contract-filter-dropdown
        v-if="statusOptions && statusOptions.length"
        query="status"
        title="Status"
        :options="statusOptions"
      />
      <contract-filter-input query="action_code" title="contracts.action_code" />
      <contract-filter-dropdown
        v-if="partnerOptions && partnerOptions.length"
        query="partner_id"
        title="Partner"
        :options="partnerOptions"
      />
      <contract-filter-dropdown
        v-if="campaignOptions && campaignOptions.length"
        query="campaign_id"
        title="Campaign"
        :options="campaignOptions"
      />
      <contract-filter-dropdown
        v-if="teamOptions && teamOptions.length"
        query="team_id"
        title="Team"
        :options="teamOptions"
      />
      <contract-filter-dropdown
        v-if="userOptions && userOptions.length"
        query="user_id"
        title="Staff"
        :options="userOptions"
      />
    </b-card-body>
    <div class="w-100 text-center pb-2">
      <b-button variant="" @click="clearFilter">
        {{ $t('Clear filter') }}
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { BButton, BCard, BCardBody } from 'bootstrap-vue';
import permissions from '@/constants/permissions';
import ContractFilterDate from './Filter/ContractFilterDate.vue';
import ContractFilterDropdown from './Filter/ContractFilterDropdown.vue';
import ContractFilterInput from './Filter/ContractFilterInput.vue';

export default {
  components: {
    BButton,
    BCard,
    BCardBody,
    ContractFilterDate,
    ContractFilterDropdown,
    ContractFilterInput,
  },
  props: {},
  data() {
    return {
      permissions,
    };
  },
  computed: {
    ...mapGetters('app-contract', {
      statusOptions: 'filterStatuses',
    }),
    ...mapGetters('app-contract', {
      partnerOptions: 'filterPartners',
    }),
    ...mapGetters('app-contract', {
      campaignOptions: 'filterCampaigns',
    }),
    ...mapGetters('app-contract', {
      teamOptions: 'filterTeams',
    }),
    ...mapGetters('app-contract', {
      userOptions: 'filterUsers',
    }),
  },
  created() {
    if (
      this.$can(
        permissions.ADVANCE_FILTER.GET_v1_advance_filter_statuses.action,
        permissions.ADVANCE_FILTER.GET_v1_advance_filter_statuses.resource,
      )
    ) {
      this.$store.dispatch('app-contract/getFilterStatuses');
    }
    if (
      this.$can(
        permissions.ADVANCE_FILTER.GET_v1_advance_filter_partners.action,
        permissions.ADVANCE_FILTER.GET_v1_advance_filter_partners.resource,
      )
    ) {
      this.$store.dispatch('app-contract/getFilterPartners');
    }
    if (
      this.$can(
        permissions.ADVANCE_FILTER.GET_v1_advance_filter_campaigns.action,
        permissions.ADVANCE_FILTER.GET_v1_advance_filter_campaigns.resource,
      )
    ) {
      this.$store.dispatch('app-contract/getFilterCampaigns');
    }
    if (
      this.$can(
        permissions.ADVANCE_FILTER.GET_v1_advance_filter_teams.action,
        permissions.ADVANCE_FILTER.GET_v1_advance_filter_teams.resource,
      )
    ) {
      this.$store.dispatch('app-contract/getFilterTeams');
    }
    if (
      this.$can(
        permissions.ADVANCE_FILTER.GET_v1_advance_filter_users.action,
        permissions.ADVANCE_FILTER.GET_v1_advance_filter_users.resource,
      )
    ) {
      this.$store.dispatch('app-contract/getFilterUsers');
    }
  },
  methods: {
    clearFilter() {
      const { query } = this.$router.currentRoute;
      if (query && Object.keys(query).length) {
        this.$router.replace({
          ...this.$router.currentRoute,
          query: {},
        });
        this.$eventBus.emit('contract:filter:clear');
        this.$eventBus.emit('contract:filter:update');
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
