<template>
  <div class="p-1" style="width: 20%">
    <label for="">{{ $t(title) }}</label>
    <v-select
      :value="selected"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="options"
      :reduce="(item) => item.value"
      @input="(value) => $emit('change', value)"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select';

export default {
  name: 'DropdownFilter',
  components: {
    vSelect,
  },
  props: {
    title: {
      type: String,
      default: 'DropdownFilter',
    },
    options: {
      type: Array,
      default: null,
    },
    selected: {
      type: [String, Number],
      default: '',
    },
  },
};
</script>
