import jwt from '@/auth/jwt/useJwt';

export function statuses() {
  return jwt.axiosIns.get('advance-filter/statuses');
}

export function partners() {
  return jwt.axiosIns.get('advance-filter/partners');
}

export function campaigns() {
  return jwt.axiosIns.get('advance-filter/campaigns');
}

export function teams() {
  return jwt.axiosIns.get('advance-filter/teams');
}

export function users() {
  return jwt.axiosIns.get('advance-filter/users');
}
