import { ref, computed } from '@vue/composition-api';

export default function useUsersList($t) {
  const refUserListTable = ref(null);

  const perPage = ref(30);
  const totalContract = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [30, 50, 100, 300];

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalContract.value,
    };
  });

  return {
    perPage,
    currentPage,
    totalContract,
    dataMeta,
    perPageOptions,
    refUserListTable,
  };
}
