<template>
  <div class="p-1" style="width: 20%">
    <b-form-group class="mr-1 mb-0" style="flex: 1">
      <label>{{ $t(title) }}</label>
      <b-form-datepicker
        v-model="value"
        :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
        locale="en-GB"
        placeholder="Chọn ngày"
      />
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup, BFormDatepicker } from 'bootstrap-vue';

export default {
  name: 'ContractFilterDate',
  components: {
    BFormGroup,
    BFormDatepicker,
  },
  props: {
    title: {
      type: String,
      default: 'ContractFilterDate',
    },
    query: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: null,
      debounce: null,
    };
  },
  watch: {
    value(newValue) {
      if (newValue !== this.$router.currentRoute.query[this.query]) {
        this.updateFilter(newValue);
      }
    },
  },
  created() {
    this.value = this.$router.currentRoute.query[this.query];
    this.$eventBus.on('contract:filter:clear', this.listenEvent);
  },
  onUnmounted() {
    this.$eventBus.off('contract:filter:clear', this.listenEvent);
  },
  methods: {
    updateFilter(value) {
      let { query } = this.$router.currentRoute;
      if (this.value) {
        query = {
          ...this.$router.currentRoute.query,
          [this.query]: value,
        };
        this.$router.replace({
          ...this.$router.currentRoute,
          query,
        });
        this.$eventBus.emit('contract:filter:update');
      }
    },
    listenEvent() {
      this.value = null;
    },
  },
};
</script>
