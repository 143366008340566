<template>
  <div>
    <contract-filter />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              {{
                $t('Showing {from} to {to} of {total} entries', {
                  from: dataMeta.from,
                  to: dataMeta.to,
                  total: dataMeta.of,
                })
              }}
            </span>
          </b-col>
          <b-col cols="8" md="8" class="d-flex align-items-center justify-content-end mb-1 mb-md-0">
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <b-pagination
              v-model="currentPage"
              :total-rows="totalContract"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <b-table
        v-if="contracts"
        ref="refUserListTable"
        class="position-relative"
        :items="contracts"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="Không có dữ liệu"
      >
        <!-- Column: customer -->
        <template #cell(customer)="data">
          <b-media vertical-align="center" role="button" @click="myHandler(data.item)">
            <!-- <template #aside>
              <b-avatar size="32" :src="data.item.avatar" :variant="'light-primary'" />
            </template> -->
            <span
              v-if="data.item.customer && data.item.customer.full_name"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.customer.full_name.toLowerCase() }}
            </span>
            <span v-else class="font-weight-bold d-block text-nowrap">{{ $t('No data') }}</span>
          </b-media>
        </template>
        <template #cell(code)="data">
          <b-media vertical-align="center" role="button" @click="myHandler(data.item)">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.code }}
            </span>
          </b-media>
        </template>
        <!-- Column: Amount -->
        <template #cell(loan_amount)="data">
          <span>{{ numberWithCommas(data.item.loan_amount) }}</span>
        </template>
        <template #cell(repayment_amount_require)="data">
          <span>{{ numberWithCommas(data.item.repayment_amount_require) }}</span>
        </template>
        <template #cell(partner)="data">
          <span class="text-warning">{{ data.item.partner && data.item.partner.name }}</span>
        </template>
        <template #cell(team)="data">
          <span class="text-primary">{{ data.item.team && data.item.team.name }}</span>
          <span v-if="data.item.team && data.item.user"> - </span>
          <span class="text-primary">{{ shortName(data.item.user && data.item.user.name) }}</span>
        </template>
        <template #cell(campaign)="data">
          <span class="text-success">{{ data.item.campaign && data.item.campaign.name }}</span>
        </template>
        <template #cell(user)="data">
          <span class="text-info">{{ data.item.user && data.item.user.name }}</span>
        </template>
        <template #cell(action_code)="data">
          <div class="index-action-code" :title="data.item.action_code">
            {{ data.item.action_code }}
          </div>
        </template>
        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            variant="primary"
            class="text-capitalize"
            :class="`badge-${data.item.status}`"
          >
            {{ $t(`contracts.status.${data.item.status}`) }}
          </b-badge>
        </template>
        <template #cell(last_action_at)="data">
          <span :title="data.item.created_at">
            {{
              data.item.last_action_at ? moment(data.item.last_action_at).format('DD-MM-YYYY') : ''
            }}
          </span>
        </template>
        <template #cell(created_at)="data">
          <span>{{ moment(data.item.created_at).format('DD-MM-YYYY') }}</span>
        </template>
        <template #cell(appointment_date)="data">
          <span>{{
            data.item.appointment_date
              ? moment(data.item.appointment_date).format('DD-MM-YYYY')
              : ''
          }}</span>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)>
          <b-form-checkbox />
        </template>
      </b-table>
      <div v-else class="text-center">
        <b-spinner style="width: 5rem; height: 5rem" class="m-3" variant="info" />
      </div>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              {{
                $t('Showing {from} to {to} of {total} entries', {
                  from: dataMeta.from,
                  to: dataMeta.to,
                  total: dataMeta.of,
                })
              }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalContract"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue';
import router from '@/router';
import vSelect from 'vue-select';
import * as moment from 'moment';
import store from '@/store';
import { ref, watch, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import { numberWithCommas, shortName } from '@core/utils/utils';
import ContractFilter from './ContractFilters.vue';
import useContractList from './useContractList';
import contractStoreModule from '../contractStoreModule';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    ContractFilter,
    vSelect,
    BFormCheckbox,
  },
  setup(props, { root: { $eventBus } }) {
    const USER_APP_STORE_MODULE_NAME = 'app-contract';
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, contractStoreModule);
    }

    const contracts = ref(null);
    const isAddNewUserSidebarActive = ref(false);
    const {
      fetchContracts,
      perPage,
      currentPage,
      totalContract,
      dataMeta,
      perPageOptions,
      refetchData,
      refUserListTable,
    } = useContractList();

    const myHandler = (val) => {
      router.push({ path: `contract/${val.id}`, query: { ...router.currentRoute.query } });
    };

    const getContracts = () => {
      contracts.value = null;
      const queries = router.currentRoute.query;
      store
        .dispatch('app-contract/fetchContracts', {
          perPage: perPage.value,
          page: currentPage.value,
          ...queries,
        })
        .then((response) => {
          const { data, meta } = response.data;
          contracts.value = data;
          totalContract.value = meta.total;
        });
    };

    const handleFilterUpdateEvent = () => {
      getContracts();
    };

    watch([currentPage, perPage], () => {
      getContracts();
    });

    $eventBus.on('contract:filter:update', handleFilterUpdateEvent);

    // UnRegister on leave
    onUnmounted(() => {
      $eventBus.off('contract:filter:update', handleFilterUpdateEvent);
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      }
    });

    getContracts();

    return {
      // Sidebar
      isAddNewUserSidebarActive,
      refUserListTable,
      fetchContracts,
      perPage,
      currentPage,
      totalContract,
      dataMeta,
      perPageOptions,
      refetchData,
      contracts,
      // Filter
      avatarText,
      numberWithCommas,
      myHandler,
      moment,
      shortName,
    };
  },
  data() {
    return {
      tableColumns: [
        // { key: 'actions', label: '', thStyle: 'width:20px' },
        // { key: 'id', label: this.$t('contracts.id'), thStyle: 'width: 110px' },
        { key: 'customer', label: this.$t('customer'), tdClass: 'text-capitalize' },
        { key: 'code', label: this.$t('contracts.code') },
        { key: 'loan_amount', label: this.$t('contracts.loan_amount') },
        {
          key: 'action_code',
          label: this.$t('contracts.action_code'),
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'appointment_date',
          label: this.$t('contracts.appointment_date'),
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: 'width: 140px',
        },
        {
          key: 'partner',
          label: this.$t('contracts.partner'),
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'campaign',
          label: this.$t('Campaign'),
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'team',
          label: this.$t('Team - Staff'),
          thClass: 'text-center',
          tdClass: 'text-center text-capitalize',
        },
        // { key: 'user', label: this.$t('Staff'), thClass: 'text-center', tdClass: 'text-center' },
        {
          key: 'status',
          label: this.$t('contracts.status'),
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: 'width: 140px',
        },
        {
          key: 'last_action_at',
          label: this.$t('contracts.last_action_at'),
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: 'width: 140px',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 110px;
}
.badge {
  &-not_call {
    background-color: #c3c3c3;
  }
  &-ptp {
    background-color: #00bcd4;
  }
  &-collected {
    background-color: #4caf50;
  }
  &-following {
    background-color: #ff9800;
  }
  &-give_up {
    background-color: #f44336;
  }
  &-available {
    background-color: #cddc39;
  }
}
.index-action-code {
  max-width: 110px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
