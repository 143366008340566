<template>
  <div class="p-1" style="width: 20%">
    <b-form-group class="mr-1 mb-0" style="flex: 1" :label="$t(title)">
      <b-form-input v-model="value" class="" :placeholder="placeholder" />
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue';

export default {
  name: 'ContractFilterInput',
  components: {
    BFormGroup,
    BFormInput,
  },
  props: {
    title: {
      type: String,
      default: 'ContractFilterDropdown',
    },
    query: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: null,
      debounce: null,
    };
  },
  watch: {
    value(newValue) {
      if (newValue !== this.$router.currentRoute.query[this.query]) {
        this.debounceChange(newValue);
      }
    },
  },
  created() {
    this.value = this.$router.currentRoute.query[this.query];
    this.$eventBus.on('contract:filter:clear', this.listenEvent);
  },
  onUnmounted() {
    this.$eventBus.off('contract:filter:clear', this.listenEvent);
  },
  methods: {
    debounceChange(value) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.updateFilter(value);
      }, 1000);
    },
    updateFilter(value) {
      let { query } = this.$router.currentRoute;
      if (this.value) {
        query = {
          ...this.$router.currentRoute.query,
          [this.query]: value,
        };
        this.$router.replace({
          ...this.$router.currentRoute,
          query,
        });
        this.$eventBus.emit('contract:filter:update');
      }
    },
    listenEvent() {
      this.value = null;
    },
  },
};
</script>
